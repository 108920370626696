import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Autocomplete, Button, Card, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import { ApiEndpoints } from '../../utils/apiUtils';

interface Glimpsebox {
    id: number,
    company_id: number,
    email: string,
    user_name: string,
    active_request_id: number,
}

export default function GlimpseboxRequestSelector(
    { isWrite }: { isWrite: boolean }
) {
    // TODO:
    //    add access level control for read/write
    const { fetchData, putData } = useAuthTokenAndAccessApi();
    const [glimpseboxes, setGlimpseboxes] = useState<Glimpsebox[]>([])
    const [requests, setRequests] = useState<any[]>([])
    const [selectedGlimpsebox, setSelectedGlimpsebox] = useState<number | null>(null)
    const [selectedRequest, setSelectedRequest] = useState<number | null>(null)

    useEffect(() => { getGlimpseboxData() }, [])


    const getGlimpseboxData = async () => {
        const glimpseboxesResponse = await fetchData(ApiEndpoints.INTERNAL_GLIMPSEBOXES);
        setGlimpseboxes(glimpseboxesResponse.data as Glimpsebox[])

        const requestsResponse = await fetchData(ApiEndpoints.INTERNAL_REQUESTS);
        setRequests(requestsResponse.data)
    }

    const requestOptions = requests.map((e) => `${e.id}: ${e.name}`)
    const glimpseboxOptions = glimpseboxes.map((e) => `${e.id}: ${e.user_name}`)

    const autoCompleteChangeRequest = (event: any, value: any) => {
        setSelectedRequest(value.split(':')[0])
    }
    const autoCompleteChangeGlimpsebox = (event: any, value: any) => {
        setSelectedGlimpsebox(value.split(':')[0])
    }

    const updateRequest = async () => {
        const params = {
            glimpsebox_user_id: selectedGlimpsebox,
            request_id: selectedRequest
        }
        await putData(ApiEndpoints.INTERNAL_GLIMPSEBOXES, null, params).then(() => {
            getGlimpseboxData()
        })
    }

    const findRequestData = (request_id: number) => {
        if (requests.length === 0) return null
        if (!request_id) return null
        return requests.find((e) => e.id === parseInt(request_id.toString()))
    }

    return <>
        <Typography variant="body1" sx={{ flexGrow: 1, pt: "14pt", }}> Glimpsebox Target Requests</Typography>
        {isWrite &&
            <Card sx={{ p: 2, width: 600 }} elevation={2} >
                <Stack spacing={2}>
                    <Typography variant="body2">
                        Select a glimpsebox to update: <br />
                    </Typography>
                    <Autocomplete
                        options={glimpseboxOptions}
                        renderInput={(params) => <TextField {...params} />}
                        size='small'
                        disableClearable
                        fullWidth
                        onChange={(e, value) => { autoCompleteChangeGlimpsebox(e, value) }}
                    />
                    <Divider />
                    <Typography variant="body2">
                        Select a new request: <br />
                    </Typography>
                    <Autocomplete
                        options={requestOptions}
                        renderInput={(params) => <TextField {...params} />}
                        size='small'
                        disableClearable
                        fullWidth
                        onChange={(e, value) => { autoCompleteChangeRequest(e, value) }}
                    />
                    <Divider />
                    <Typography variant="body2">
                        updating glimpsebox: {selectedGlimpsebox} <br />
                        <br />
                        to use request: {selectedRequest} <br />
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<SaveOutlinedIcon />}
                        onClick={updateRequest}
                        disabled={selectedGlimpsebox === null || selectedRequest === null}
                    >
                        Update new request
                    </Button>
                </Stack>
            </Card>
        }
        <Stack direction="column" spacing={2} sx={{ m: 2 }}>
            {
                glimpseboxes.map((glimpsebox) => (
                    <Card key={glimpsebox.id} sx={{ p: 2 }} variant='outlined'>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <Typography variant="body2" >
                                    Glimpsebox: {glimpsebox.id} <br />
                                    user name: {glimpsebox.user_name} <br />
                                    email: {glimpsebox.email}<br />
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />

                            <Grid item xs={5}>
                                <Typography variant="body2">
                                    Writing to current request: <br />
                                    id: {glimpsebox.active_request_id} <br />
                                    name: {findRequestData(glimpsebox.active_request_id)?.name} <br />
                                    cell model: {findRequestData(glimpsebox.active_request_id)?.cell_model_name} <br />
                                    data (used for scan_info override): {JSON.stringify(findRequestData(glimpsebox.active_request_id)?.data)}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Card>
                ))
            }
        </Stack>
    </>
};
