import { Auth0Provider } from "@auth0/auth0-react";
import App from "../App";

export const AuthProviderWrapper = () => {
    return <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        authorizationParams={{
            redirect_uri: window.location.origin,
        }}
    >
        <App />
    </Auth0Provider>
};
