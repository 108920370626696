import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AppInitialDataLoading from './components/AppInitialDataLoading';

function App() {
    return (
        <main >
            <AppInitialDataLoading />
        </main >
    )
}

// Wrap in router to enable using the url for state storage
let Name = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Name
