// TODO: build out real color options, this is just a placeholder

import { ManualMeasurementOptions } from "../types"
import { AutomatedInspectionMetrics } from "./inspection"

// Charts
export const colorScheme = {
    average: '#89C3FA',
    min: '#DF9284',
    max: '#90CCBE',
    chartText: '#FFF',
}

// from Kristen, muted ones:
// Bright blue - #89C3FA
// Green - #90CCBE
// Orange - #EACBA8
// Red - #DF9284
// Pink - #DF84AD

// Overlays - more intense with a 98 opacity:
// TODO could add the 98 programmatically I guess
export const overlayColorScheme = {
    blue: '#18A0FF',
    green: '#18B38F',
    orange: '#F29D3D',
    red: '#EB6249',
    pink: '#DC3881',
}

export const portalColorScheme = {
    lightBlue: '#77C5FF', //mainly used in faq page
    mutedBlue: '#1A2243', //mainly used in faq page
    white: '#FFF', //mainly used for text color
}

export const colorAnnotation = (metricName: AutomatedInspectionMetrics | ManualMeasurementOptions) => {
    let colorLocal = overlayColorScheme.blue
    const orangeMetrics = [
        AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
        AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
        AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
    ] as string[]
    const greenMetrics = [
        AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
        AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN
    ] as string[]
    if (orangeMetrics.includes(metricName)) { colorLocal = overlayColorScheme.orange }
    if (greenMetrics.includes(metricName)) { colorLocal = overlayColorScheme.green }
    return colorLocal.concat("99")
}
