import Logout from '@mui/icons-material/Logout';
import { Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import { useAuthTokenAndAccessApi } from "../auth/authHooks";

export default function LogoutButton() {
    const { logout } = useAuthTokenAndAccessApi();
    return (
        <Tooltip title={<Typography variant="body1">Log Out</Typography>}>
            <IconButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                <Logout />
            </IconButton>
        </Tooltip>
    )
};
